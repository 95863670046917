import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { TableSelectionRoutingModule } from './table-selection-routing.module';
import { TableNameChoosingComponent } from 'src/modules/table-selection/components/table-name-choosing/table-name-choosing.component';@NgModule({
  imports: [
    CommonModule,
    TableSelectionRoutingModule
  ],
  declarations: [
    TableNameChoosingComponent
  ],
  entryComponents: [
    TableNameChoosingComponent
  ]
})
export class TableSelectionModule { }
