export enum notificationRefreshStrings {
    refresh_ongoing_process_api= 'refresh_ongoing_process_api',
    refresh_file_action_data_api= 'refresh_file_action_data_api',
    sales_data_transformation_completed= 'sales_data_transformation_completed',
    shared_dashboard= 'shared_dashboard',
    refresh_notifications= 'refresh_notifications'
}
export enum notificationItenFunctions {
    sharing_dashboard= 'sharing_dashboard',
    structured_fileupload= 'structured_fileupload',
    unstructured_fileupload= 'unstructured_fileupload',
    dominant_segmentation= 'dominant_segmentation',
    trend_segmentation= 'trend_segmentation',
    decile_segmentation= 'decile_segmentation',
    quantile_segmentation= 'quantile_segmentation',
    control_data_success= 'control_data_success'
}
