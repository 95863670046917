export class notificationsRes {
     count: number;
     fileuploadalerts: notificationItem[] = [];

}
export class notificationItem {
    alert_id: number;
alert_status: boolean;
clickable: boolean;
created_at: string;
function: string;
item_id: number;
item_name: string;
message: string;
module: string;
org_id: 1;
sub_item: string;
type: string;
updated_at: string;
user_login_id: number;

}
export class actionsRes {
    fileactiondata: actionSheetData[] = [];
    alertdata: categoryItem[] = [];
filecount: number;
alertcount: number;
}
export class actionSheetData {
    file_name: string;
sheet: string;
user_file_upload_id: number;
}
export class categoryItem {
    dmin_id: number;
category_nm: string;
category_type:  string;
created_dttm:  string;
last_modified:  string;
org_id: number;
project_category_id: number;
total_views: number;
user_login_id: number;
user_name:  string;
}
