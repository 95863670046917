export class projectSummary {
    data: Array<projectData>;
    responseCode: string;
    responseMessage: string;
    validations: validations[];
}
export class validations {
    constructor(message: string, errCode: string) {}
}
export class projectData {
    userLoginId: number;
    orgId: number;
    adminId: number;
    userType: string;
    projectCategoryId: number;
    projectId: number;
    projectNm: string;
    isactive: boolean;
    createdDttm: string;
    description: string;
    categoryNm: string;
    googleTrendsObservation: boolean;
    richtext: string;
    categoryType: string;
    lastModified: string;
    totalViews: number;
    keyWords: string;
    username: string;
    pinnedProjectListRes: Array<pinnedProject> = [];
    projectFilesRes: Array<projectFiles>;
    project_dates: projectDates;
    milestones: Array<milesStones>;
    stackeholders: Array<stakeHolders>;
    projectName: string;
    profilepic: string;
    roleId: number;
}
export class projectDates {
    prj_start_date: string;
    prj_end_date: string;
}
export class milesStones {
    milestone_end_date: string;
    milestone_start_date: string;
    milestone_discription: string;
}
export class pinnedProject {
    // constructor(pinnedProjectId: number,
    //     pinnedProjectNm: number,
    //     projectFilesRes: any=[],
    //     projectUserId: number,
    //     userLoginId: number,
    //     userPinnedProjectId: number){}
    pinnedProjectId: number;
        pinnedProjectNm: number;
        projectFilesRes: any = [];
        projectUserId: number;
        userLoginId: number;
        userPinnedProjectId: number;
}
export class projectFiles {

}
export class recommendedProjectsModel {
    description: string;
    key_words: string;
    project_id: number;
    project_nm: string;
    user_login_id: number;
}
export class recommendedProjectsRes {
    data: recommendedProjectsModel[];
    status: boolean;
}
export class insertProjectRes {
    message: string;
project_id: number;
status: boolean;
}

export class projectDataResDataFormat {
    Otherprojects: ProjectData[];
    Myprojects: ProjectData[];
}
export class projectDataRes {
    data: projectDataResDataFormat = new projectDataResDataFormat();
responseCode: string;
responseMessage: string;
validations: validations[];
}

export class ProjectData {
    projectId: number;
    projectNm: string;
    created_dttm: Date;
    userLoginId: number;
    orgId: number;
    description: string;
    projectCategoryId: number;
  }
  export class projectCategory {
    categoryNm: string;
categoryType: string;
createdDttm: string;
lastModified: string;
orgId: number;
projectCategoryId: number;
totalViews: number;
userLoginId: number;
userName: string;
  }
  export class changeKeywordScoreReq {
    action: string;
    keyword: string;
    userid: number;
  }
export class usersList {
    designation: string;
id: number;
username: string;
}
export class stakeHolders {
    role: any;
userId: any;
username: string;
}
export interface stakeHolderType {
    id: number;
    roleName: string;
}
export interface ProjectSummaryModalData {
    projectId: number;
    isRecommendProject: boolean;
  }
  export interface AddkeywordsDialogData {
    keywords: any[];
    projectId: any;
  }
  export interface projecttable{
    projectNm:string;
    description:string;
    projectCategoryId:any;
    action:any
  }
