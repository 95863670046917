import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { ProductCheckRoutingModule } from './product-check-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ProductCheckComponent } from 'src/modules/product-check/components/product-check/product-check.component';@NgModule({
  imports: [
    CommonModule,
    ProductCheckRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
  ],
  declarations: [
    ProductCheckComponent
  ],
  entryComponents: [
    ProductCheckComponent
  ]
})
export class ProductCheckModule { }
