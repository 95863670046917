import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewModalComponent } from 'src/modules/file-viewer/components/file-view-modal/file-view-modal.component';
import { MaterialModule } from '../material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TableModule } from '../table/table.module';@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    NgxDocViewerModule,
    PdfViewerModule,
    TableModule
  ],
  declarations: [
    FileViewModalComponent
  ],
  entryComponents: [
    FileViewModalComponent
  ]
})
export class FileViewerModule { }
