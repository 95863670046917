import { chartsListModel } from './trackerMyCharts.model';

export class trackerDashboard {
    allDashboads: dashboardsList[] = [];
    selectedDashId: number;
    selectedDashData: any;
    allGraphs: any = [];
    dashName: string;
}
export class dashboardsList {
dashboard_chart_ids: number[];
        dashboard_created_at: string;
        dashboard_id: number;
        dashboard_name: string;
        last_modified: string;
        constructor(dashboard_chart_ids: number[],
            dashboard_created_at: string,
            dashboard_id: number,
            dashboard_name: string,
            last_modified: string) {

        }
}
export class dashboardData {
    chart_id_list: number[];
    created_at: string;
    dashboard_id: number;
    dashboard_name: string;
    is_my_dashboard: true;
    last_modified: string;
    no_of_charts: number;
    org_id: number;
    private: null;
    project_id: number[];
    response: chartsListModel[];
    user_login_id: number;
}
