import { MenuItemsModel } from 'src/models/common.model';
import { IngenOptimService } from 'src/providers/ingen-optim.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export class formatMenuItems {

    AnalysisName: string=''
    UpdateditemsList: any=[];
    menuitems: string[];
    constructor( public ingenoptimservice: IngenOptimService, public router: Router
       ){
        
    }
    getAnalysisName(AnalysisName){
   
     this.AnalysisName=AnalysisName
     console.log(this.AnalysisName)
     if(this.AnalysisName){
    //    this.updateBreadcrumb(this.AnalysisName)
    }
    
    
    }
    
    
        
    itemsList: activeMenuitemObj[] = [
        {'path': 'menu', 'name': '' },
        {'path': 'ingen-data', 'name': 'IngenData' },
        // {'path': 'upload', 'name': 'Upload' },
        {'path': 'connections', 'name': 'Connections' },
        {'path': 'ingen-connect', 'name': 'IngenConnect' },
        {'path': 'project', 'name': ''},
        {'path':'segmentation','name':'Segmentations'},
        // {'path':'','name':'segmentations'},
        {path: 'project-summary', name: 'Project Summary'},
        {path: 'qa', name: 'QA'},
        {path: 'all-uploads', name: 'Organization Data'},
        {path: 'project-data', name: 'Project Datasets'},
        {path: 'pipelines', name: 'Pipelines'},
        {path: 'merge', name: 'Merge Datasets'},
        {path: 'edit-project', name: 'Edit Project'},
        {path: 'qa-answers', name: 'Answers'},
        {path: 'ingenCustomer', name: 'IngenCustomer'},
        {path: 'create', name: 'Create'},
        {path:'open',name:'Open'},
        {path:'compare',name:'Compare'},
        {path: 'decile', name: 'Decile'},
        {path: 'createSegment', name: ''},
        {path: 'openSegments', name: ''},
        {path: 'trend', name: 'Trend'},
        {path: 'createTSegment', name: ''},
        {path: 'openTSegments', name: ''},
        {path: 'dominant', name: 'Dominant'},
        {path: 'custom', name: 'Custom'},
        {path: 'combine', name: 'Combine'},
        {path: 'createDcSegment', name: ''},
        {path: 'openDcSegments', name: ''},
        {path: 'quantile', name: 'Quartile'},
        {path: 'createQSegment', name: ''},
        {path: 'openQSegments', name: ''},
        {path: 'ingenOptim', name: 'IngenOptim'},
        {path:'a-bTesting',name:'A-B Testing'},
        {path:'a-bSetup',name:'Setup'},
        {path:'a-bControlSelection',name:'Control Selection'},
        {path:'a-bAnalysis',name:'Analysis'},
        {path:'a-bReports',name:'Reports'},
        {path:'drivers',name:'Performance Drivers'},
        
        {path:'driverSetup',name:'Setup'},
        {path:'driverAnalysis',name:'Analysis'},
        {path:'driverReports',name:'Reports'},
        {path:'mmm',name:'Marketing Mix'},
        {path:'setup',name:'Setup'},
        {path:'reports',name:'Reports'},
        {path:'analysis',name:'Analysis'},
        {path:'roi',name:'Roi'},
        {path:'budget-optimization',name:'Budget Optimization'},
        {path: 'tracker', name: 'IngenTracker'},
        {path:'trackerDashboard',name:"My Dashboards"},
        {path:'myCharts',name:"My Charts"},
        {path:'createChart',name:"Create Chart"},
        {path: 'trackerDashboard', name: ''},
        {path: 'myCharts', name: ''},
        {path: 'sharedDashboards', name: ''},
        {path: 'createChart', name: ''},
        // {path:'setUpId',name:this.AnalysisName}
    ];
   
    getMenuItems(activeItems: any[]) {
        const items = [];
        activeItems = activeItems.filter(x => isNaN(x));
        activeItems.forEach(activeItem => {
            // this.itemsList.find(item=>item.path===activeItem);
            const item = this.itemsList.find(item => item.path === activeItem);
            if (item && item.name) {
                items.push(item);
            }

        });
        return items;
    }
    // updateBreadcrumb(analysisName: string): void {
    //     const setupItemIndex = this.itemsList.findIndex(item => item.path === 'setup');
    //     if (setupItemIndex !== -1) {
    //       this.itemsList[setupItemIndex].name = `${ this.itemsList[setupItemIndex].name} / ${this.AnalysisName}`;
    //       console.log(this.itemsList[setupItemIndex].name)
    //       this.UpdateditemsList=this.itemsList
    //       this.ingenoptimservice.updateItemsList(this.UpdateditemsList)
    //       this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
    //         const root: ActivatedRoute = this.ac.root;
    //         console.log(this.ac.root)
    //         if ((event.urlAfterRedirects.includes('?'))) {
    //           this.menuitems = event.urlAfterRedirects.substring(0, (event.urlAfterRedirects.indexOf('?'))).split('/').slice(1);
    //           console.log(this.menuitems)
    //           this.getUpdatedMenuItems(this.menuitems.splice(1))
    //         }
          
    //         })
    //     }
    // }


//     getUpdatedMenuItems(activeItems:any[]){
//         const items = [];
//         activeItems = activeItems.filter(x => isNaN(x));
//         activeItems.forEach(activeItem => {
//         const item = this.UpdateditemsList.filter(item => item.path === activeItem);
//         if (item) {
//             items.push(item[0]);
//         }
//     })
//     return items;
// }
}
export class activeMenuitemObj {
    path: string;
    name: string;
}
