import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';import {environment} from 'src/environments/environment';
import { UsersService } from './users.service';
@Injectable({
  providedIn: 'root'
})
export class GoogletrendsService {  constructor(private http: HttpClient, private userService: UsersService) {}  GoogleTrends(keyword, region) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'Gtrends?kw_list=' + keyword + '&geo=' + region + '&user_login_id=' + this.userService.getUserId())
        .pipe(map((response: any) => response));
  }
  gTrendsObservations(keywords) {
    return this.http.get(environment.PYTHON_API_ENDPOINT + 'Gtrends_observations?kw_list=' + keywords + '&user_login_id=' + this.userService.getUserId())
    .pipe(map((response: any) => response));
  }
}